import { Types } from './types'

const initialState = {
    dataUserClient: {
        paging: {},
        rows: [],
    },
    dataUserClientById: {},
    dataThongKeUser: {},
    dataHistoryChangeCoinById: {
        paging: {},
        rows: [],
    },
    dataReferral: {
        paging: {},
        rows: [],
    },
    dataUserReferralById: {
        paging: {},
        rows: [],
    },
    dataListKYC: {
        paging: {},
        rows: [],
    },
    dataUserKYC: {
        paging: {},
        rows: [],
    }
}
const reducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.SAVE_LIST_USER_CLIENT:
            return {
                ...newState,
                dataUserClient: action.payload
            }
        case Types.SAVE_USER_CLIENT_BY_ID:
            return {
                ...newState,
                dataUserClientById: action.payload
            }
        case Types.SAVE_STATISTIC_USER:
            return {
                ...newState,
                dataThongKeUser: action.payload
            }
        case Types.SAVE_HISTORY_CHANGE_COIN_BY_ID:
            return {
                ...newState,
                dataHistoryChangeCoinById: action.payload
            }
        case Types.SAVE_LIST_REFERRAL:
            return {
                ...newState,
                dataReferral: action.payload
            }
        case Types.SAVE_USER_REFERRAL_BY_ID:
            return {
                ...newState,
                dataUserReferralById: action.payload
            }
        case Types.SAVE_LIST_USER_KYC:
            return {
                ...newState,
                dataListKYC: action.payload
            }
        case Types.SAVE_USER_KYC:
            return {
                ...newState,
                dataUserKYC: action.payload
            }
        default:
            return { ...newState }
    }
}
export default reducer
