/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { Link } from "react-router-dom";
//import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import objectPath from "object-path";
import { useHtmlClassService } from "../../../_core/MetronicLayout";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { LanguageSelectorDropdown } from "./LanguageSelectorDropdown";
import { actionSaveInfoUser } from '../../../../../redux/auth/actions'
import { Dropdown, Menu } from 'antd';
// import * as TYPE from '../../../../../app/lib/contrant'

export function UserProfileDropdown({ history }) {

  const dispatch = useDispatch();
  const { infoUser } = useSelector(state => ({
    infoUser: state.authReducer.infoUser,
    listUserLoginPermission: state.authReducer.listUserLoginPermission,
  }), shallowEqual)

  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      light:
        objectPath.get(uiService.config, "extras.user.dropdown.style") ===
        "light",
    };
  }, [uiService]);

  const onLogout = async () => {
    if (window.confirm("Do you want to logout?")) {
      await Promise.all([
        localStorage.removeItem("token"),
        dispatch(actionSaveInfoUser(null))
      ])
      return window.location.reload("/login")
    }
  }
  const menu = (
    <div className="navi navi-spacer-x-0 dropdown-antd-custom">
            {/* quản lý trang cá nhân */}
            <Link to="/admin/profile" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2 customIconRightDashboard">
                  <i className="flaticon2-calendar-3 text-success" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    Trang cá nhân
                  </div>
                  <div className="text-muted ml--1">
                    Quản lý trang cá nhân
                  </div>
                </div>
              </div>
            </Link>
            <div className="navi-separator mt-3"></div>

            {/* đổi mật khẩu */}
            <Link to="/admin/change-password" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2 customIconRightDashboard">
                  <i className="flaticon2-calendar-1 text-success" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    Đổi mật khẩu
                  </div>
                  <div className="text-muted ml--1">
                    Quản lý mật khẩu
                  </div>
                </div>
              </div>
            </Link>
            <div className="navi-separator mt-3"></div>

            <div className="navi-footer px-8 py-5">
              <a href="#"
                className="btn btn-light-primary font-weight-bold"
                onClick={onLogout}
              >
                Đăng xuất
              </a>
              {/* <a href="#" className="btn btn-clean font-weight-bold">
              Upgrade Plan
            </a> */}
            </div>

          </div>
  );
  return (
   <>
    <Dropdown overlay={menu} >
      <div
      className={
        "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
      }
    >
      <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
        Xin chào,
      </span>{" "}
      <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
        {infoUser?.fullname || ""}
      </span>
    </div>
    </Dropdown>
   </>
  );
}
