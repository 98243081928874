import { Types } from './types'

const initialState = {
    // dataCategory: [],
    dataMember:{
        paging :{},
        rows : [],
    }
}
const reducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // case Types.SAVE_LIST_CATEGORY:
        //     return {
        //         ...newState,
        //         dataCategory: action.payload
        //     }
        case Types.SAVE_LIST_MEMBER:
            return {
                ...newState,
                dataMember : action.payload
            }
        default:
            return { ...newState }
    }
}
export default reducer
