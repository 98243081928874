/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React, { useEffect, lazy } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { AuthPage } from "./modules/Auth";
import { actionLoginByToken } from '../redux/auth/actions'



export function Routes() {
  const dispatch = useDispatch()
  const { infoUser } = useSelector(state => ({
    infoUser: state.authReducer.infoUser,
  }), shallowEqual)

  const token = localStorage.getItem("token")

  useEffect(() => {
    (async () => {
      if (localStorage.getItem("token")) {
        // await dispatch(actionLoginByToken());
      }
    })();
  }, [dispatch])

  return (
    <>
      <Switch>
        {(infoUser || token) ? (
          <Layout>
            <BasePage />
          </Layout>

        ) : (
          <Switch>            
            {/* end */}
            <Route path="/login" component={AuthPage} />
            <Redirect to="/login" />
          </Switch>
        )}
      </Switch>
    </>
  );
}
