import { Types } from './types'

const initialState = {
    dataDailyCode: {
        paging: {},
        rows: []
    },
    dataTelegramDailyCode: {
        paging: {},
        rows: []
    },
    dataTelegramDailyCodeDetail: {
        paging: {},
        rows: []
    },
}
const dailyCodeReducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case Types.GET_DAILY_CODE:
            return {
                ...newState,
                dataDailyCode: action.payload
            }
        case Types.GET_TELEGRAM_DAILY_CODE:
            return {
                ...newState,
                dataTelegramDailyCode: action.payload
            }
        case Types.GET_TELEGRAM_DAILY_CODE_DETAIL:
            return {
                ...newState,
                dataTelegramDailyCodeDetail: action.payload
            }
        default:
            return { ...newState }
    }
}
export default dailyCodeReducer
