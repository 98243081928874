import { Types } from './types'

const initialState = {
    // dataCategory: [],
    dataProject: {
        rows: [],
        paging: {}
    },
    // dataPostCategory: {
    //     rows: [],
    //     paging: {}
    // },
    dataTag: [],
    // dataAllTag: []
}
const reducer = (state = initialState, action) => {
    let newState = Object.assign({}, state);
    switch (action.type) {
        // case Types.SAVE_LIST_CATEGORY:
        //     return {
        //         ...newState,
        //         dataCategory: action.payload
        //     }
        case Types.SAVE_LIST_PROJECT:
            return {
                ...newState,
                dataProject: action.payload
            }
        // case Types.SAVE_LIST_POST_CATEGORY:
        //     return {
        //         ...newState,
        //         dataPostCategory: action.payload
        //     }
        case Types.SAVE_LIST_PROJECT_TAG:
            return {
                ...newState,
                dataTag: action.payload
            }
        // case Types.SAVE_ALL_PROJECT_TAG:
        //     return {
        //         ...newState,
        //         dataAllTag: action.payload
        //     }
        default:
            return { ...newState }
    }
}
export default reducer
